<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorSanche};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="date" :style="`color: ${statusTimeSanche};`">
          조회시간: {{ str_time }}
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          :style="`border: 2px solid ${statusMesborTblSanche}`"
          v-for="(items, index) in filterProductList"
          :key="index"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="9"
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  완제품 재고현황
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  제품명
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  규격
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  수량(단위)
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  보관창고
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index2) in items" :key="product.id">
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 0; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ product.name }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ product.standard }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ $makeComma(product.nowStock_unit) }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ product.storeLocation }}
                </td>
              </tr>
            </tbody>
          </table>
        </swiper-slide>
        <!-- </div> -->
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent.stop="autoPlay"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [SWIPER_MIXIN, fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTimeSanche: localStorage.getItem('statusTimeSanche'),
      statusArticleColorSanche: localStorage.getItem(
        'statusArticleColorSanche',
      ),
      statusMesborTblSanche: localStorage.getItem('statusMesborTblSanche'),
      statusMesboriTblSanche: localStorage.getItem('statusMesboriTblSanche'),
      statusMesboriTblSanche2: localStorage.getItem('statusMesboriTblSanche2'),
      statusTempbgColorSanche: localStorage.getItem('statusTempbgColorSanche'),
      statusTempFontSanche: localStorage.getItem('statusTempFontSanche'),
      statusDivColorSanche: localStorage.getItem('statusDivColorSanche'),
      statusDivFontSanche: localStorage.getItem('statusDivFontSanche'),
      statusTableColorSanche: localStorage.getItem('statusTableColorSanche'),
      statusDetailFontSanche: localStorage.getItem('statusDetailFontSanche'),
      statusDetailTempFontSanche: localStorage.getItem(
        'statusDetailTempFontSanche',
      ),
      statusBtnColorSanche: localStorage.getItem('statusBtnColorSanche'),
      statusBtnFontColorSanche: localStorage.getItem(
        'statusBtnFontColorSanche',
      ),
      statusSlideBtnColorSanche: localStorage.getItem(
        'statusSlideBtnColorSanche',
      ),
      statusSlideBtniColorSanche: localStorage.getItem(
        'statusSlideBtniColorSanche',
      ),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct', // 일단 그냥 확인
      productTotalStock: 'getProductTotalStock',
      stockStatusList: 'getProductStockStatusListFromProductStockStatus',
      productInOutType: 'getProductInOutType',
      productControlList: 'getProductControl',
      unitCodes: 'getUnitCodes',
      boxs: 'getBoxs', // 현재고 출고박스 기준  box에서 box_unit_id로 확인, null 이면 낱개
      stores: 'getStores',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filterProductList() {
      let productsList = [];

      if (
        this.productTotalStock.length > 0 &&
        this.productInOutType.length > 0
      ) {
        let productTotalStockClone = this.lodash
          .clonedeep(this.productTotalStock)
          .filter(x => x.stock_quantity > 0)
          .sort((a, b) => a.product_id - b.product_id);

        productTotalStockClone.forEach(el => {
          let productInfo = this.findInfoFromId(this.products, el.product_id);
          let foundLastProductControl = this.filterStoreLocation.filter(
            x => x.product_id == el.product_id,
          );
          const lastStoreId =
            foundLastProductControl.length > 0
              ? foundLastProductControl[0].store_id
              : null;

          let beforeStock = el.stock_quantity;
          let inStockSum = 0;
          let outStockSum = 0;

          if (this.filterStockStatusList[0].length > 0) {
            inStockSum = this.lodash
              .clonedeep(this.filterStockStatusList[0])
              .filter(
                x =>
                  x.product_id == el.product_id &&
                  (x.product_in_out_type_id ==
                    this.productInOutType.find(y => y.detail == 'process in')
                      .id ||
                    x.product_in_out_type_id ==
                      this.productInOutType.find(y => y.detail == 'return in')
                        .id),
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            outStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.product_id == el.product_id &&
                  (x.product_in_out_type_id ==
                    this.productInOutType.find(y => y.detail == 'sales out')
                      .id ||
                    x.product_in_out_type_id ==
                      this.productInOutType.find(y => y.detail == 'using out')
                        .id),
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            beforeStock = this.$decimalSub(
              this.$decimalAdd(el.stock_quantity, outStockSum),
              inStockSum,
            );
          }

          productsList.push({
            name: productInfo.name,
            standard: productInfo.standard,
            beforeStock_unit:
              this.$makeComma(beforeStock) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            beforeStock_box:
              productInfo.box_unit_id == null
                ? this.$makeComma(beforeStock)
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        beforeStock,
                        this.findInfoFromId(this.boxs, productInfo.box_unit_id)
                          .quantity,
                      ),
                    ),
                  ),
            inStock:
              this.$makeComma(inStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            outStock:
              this.$makeComma(outStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.delivery_unit_id)
                .name +
              ')',
            nowStock_unit:
              this.$makeComma(el.stock_quantity) +
              '(' +
              this.findInfoFromId(this.unitCodes, productInfo.stock_unit_id)
                .name +
              ')',
            nowStock_box:
              productInfo.box_unit_id == null
                ? this.$makeComma(el.stock_quantity)
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        el.stock_quantity,
                        this.findInfoFromId(this.boxs, productInfo.box_unit_id)
                          .quantity,
                      ),
                    ),
                  ),
            storeLocation:
              lastStoreId == null
                ? '-'
                : this.findInfoFromId(this.stores, lastStoreId).name,
          });
        });
      }
      let arr = [];
      if (this.fullScreen) {
        productsList.forEach((element, index) => {
          if (index % 9 == 0) {
            arr.push(productsList.slice(index, index + 9));
          }
        });
      } else {
        productsList.forEach((element, index) => {
          if (index % 5 == 0) {
            arr.push(productsList.slice(index, index + 5));
          }
        });
      }
      return arr;
    },
    filterStockStatusList() {
      let stockStatusClone = this.lodash.clonedeep(this.stockStatusList);

      if (this.stockStatusList.length > 0) {
        let today = new Date();
        today = yyyymmdd(today);

        const todayList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) == today);

        const beforeList = stockStatusClone.filter(
          x => x.create_time.substr(0, 10) != today,
        );

        return [todayList, beforeList];
      } else {
        return [[], []];
      }
    },
    filterStoreLocation() {
      let storeLoctionClone = this.lodash.clonedeep(this.productControlList);

      if (this.productControlList.length > 0) {
        storeLoctionClone = storeLoctionClone.sort(
          (b, a) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
      }
      return storeLoctionClone;
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_CONTROL', '재고 관리');
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS', '제품 현황');
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고');
    }
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');

    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.productInOutType.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '제품 입출고 유형');
    }

    await this.FETCH('FETCH_PRODUCT_STATUS', '제품 현황');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_PRODUCT_CONTROL', '재고 관리');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS', '제품 현황');
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_CONTROL', '재고 관리');
      }

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
