<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <form class="status_color_form">
        <ul>
          <li>
            <div>
              <input
                id="statusThBgColorSanche"
                type="color"
                v-model="status_background_pick"
              />
            </div>
            <label for="statusThBgColorSanche">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="statusTimeSanche" type="color" v-model="status_time" />
            </div>
            <label for="statusTimeSanche">조회시간 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusMesborTblSanche"
                type="color"
                v-model="status_border_pick"
              />
            </div>
            <label for="statusMesborTblSanche">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusMesboriTblSanche"
                type="color"
                v-model="status_border_inner"
              />
            </div>
            <label for="statusMesboriTblSanche">테이블 내부 제목 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusMesboriTblSanche2"
                type="color"
                v-model="status_border_inner2"
              />
            </div>
            <label for="statusMesboriTblSanche2">테이블 내부 내용 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtColorSanche"
                type="color"
                v-model="status_background_temp"
              />
            </div>
            <label for="statusThCrtColorSanche">테이블 제목 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtFontSanche"
                type="color"
                v-model="status_color_temp"
              />
            </div>
            <label for="statusThCrtFontSanche"> 테이블 제목 글자</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="statusThTableColorSanche"
                type="color"
                v-model="status_background_table"
              />
            </div>
            <label for="statusThTableColorSanche">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input
                id="statusThDetailColorSanche"
                type="color"
                v-model="status_color_detail"
              />
            </div>
            <label for="statusThDetailColorSanche">테이블 내용 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBtnColorSanche"
                type="color"
                v-model="status_background_button"
              />
            </div>
            <label for="statusThBtnColorSanche"
              >전체화면&재조회 버튼 배경</label
            >
          </li>
          <li>
            <div>
              <input
                id="statusThBtnFontSanche"
                type="color"
                v-model="status_color_button"
              />
            </div>
            <label for="statusThBtnFontSanche">전체화면&재조회 버튼 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideColorSanche"
                type="color"
                v-model="status_background_slide_btn"
              />
            </div>
            <label for="statusThSlideColorSanche">이전/다음 버튼 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideIconSanche"
                type="color"
                v-model="status_color_slide_btn"
              />
            </div>
            <label for="statusThSlideIconSanche">이전/다음 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button class="btn_sub2" @click.prevent="showModal($event)">
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormColor">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeColor()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      status_time: '#004a89',
      status_background_pick: '#fbfbfb',
      status_border_pick: '#2a3881',
      status_border_inner: '#ffffff',
      status_border_inner2: '#2a3881',
      status_background_temp: '#2a3881',
      status_color_temp: '#ffffff',
      status_background_table: '#ffffff',
      status_color_detail: '#2a3881',
      status_background_button: '#eaeaea',
      status_color_button: '#000000',
      status_background_slide_btn: '#ffffff',
      status_color_slide_btn: '#000000',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  mounted() {
    this.status_time = localStorage.getItem('statusTimeSanche');
    this.status_background_pick = localStorage.getItem(
      'statusArticleColorSanche',
    );
    this.status_border_pick = localStorage.getItem('statusMesborTblSanche');
    this.status_border_inner = localStorage.getItem('statusMesboriTblSanche');
    this.status_border_inner2 = localStorage.getItem('statusMesboriTblSanche2');
    this.status_background_temp = localStorage.getItem(
      'statusTempbgColorSanche',
    );
    this.status_color_temp = localStorage.getItem('statusTempFontSanche');

    this.status_background_table = localStorage.getItem(
      'statusTableColorSanche',
    );
    this.status_color_detail = localStorage.getItem('statusDetailFontSanche');
    this.status_background_button = localStorage.getItem(
      'statusBtnColorSanche',
    );
    this.status_color_button = localStorage.getItem('statusBtnFontColorSanche');
    this.status_background_slide_btn = localStorage.getItem(
      'statusSlideBtnColorSanche',
    );
    this.status_color_slide_btn = localStorage.getItem(
      'statusSlideBtniColorSanche',
    );
  },

  methods: {
    showModal() {
      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    submitFormColor() {
      localStorage.setItem('statusTimeSanche', this.status_time);
      localStorage.setItem(
        'statusArticleColorSanche',
        this.status_background_pick,
      );
      localStorage.setItem('statusMesborTblSanche', this.status_border_pick);
      localStorage.setItem('statusMesboriTblSanche', this.status_border_inner);
      localStorage.setItem(
        'statusMesboriTblSanche2',
        this.status_border_inner2,
      );
      localStorage.setItem(
        'statusTempbgColorSanche',
        this.status_background_temp,
      );
      localStorage.setItem('statusTempFontSanche', this.status_color_temp);
      localStorage.setItem(
        'statusDivColorSanche',
        this.status_background_division,
      );
      localStorage.setItem('statusDivFontSanche', this.status_color_division);
      localStorage.setItem(
        'statusTableColorSanche',
        this.status_background_table,
      );
      localStorage.setItem('statusDetailFontSanche', this.status_color_detail);
      localStorage.setItem(
        'statusDetailTempFontSanche',
        this.status_color_detail_temp,
      );
      localStorage.setItem(
        'statusBtnColorSanche',
        this.status_background_button,
      );
      localStorage.setItem(
        'statusBtnFontColorSanche',
        this.status_color_button,
      );
      localStorage.setItem(
        'statusSlideBtnColorSanche',
        this.status_background_slide_btn,
      );
      localStorage.setItem(
        'statusSlideBtniColorSanche',
        this.status_color_slide_btn,
      );

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeColor() {
      localStorage.setItem('statusTimeSanche', '#004a89');
      localStorage.setItem('statusArticleColorSanche', '#fbfbfb');
      localStorage.setItem('statusMesborTblSanche', '#2a3881');
      localStorage.setItem('statusMesboriTblSanche', '#ffffff');
      localStorage.setItem('statusMesboriTblSanche2', '#2a3881');
      localStorage.setItem('statusTempbgColorSanche', '#2a3881');
      localStorage.setItem('statusTempFontSanche', '#ffffff');
      localStorage.setItem('statusTableColorSanche', '#ffffff');
      localStorage.setItem('statusDetailFontSanche', '#2a3881');
      localStorage.setItem('statusBtnColorSanche', '#eaeaea');
      localStorage.setItem('statusBtnFontColorSanche', '#000000');
      localStorage.setItem('statusSlideBtnColorSanche', '#ffffff');
      localStorage.setItem('statusSlideBtniColorSanche', '#000000');

      this.status_time = '#004a89';
      this.status_background_pick = '#fbfbfb';
      this.status_border_pick = '#2a3881';
      this.status_border_inner = '#ffffff';
      this.status_border_inner2 = '#2a3881';
      this.status_background_temp = '#2a3881';
      this.status_color_temp = '#ffffff';
      this.status_background_table = '#ffffff';
      this.status_color_detail = '#2a3881';
      this.status_background_button = '#eaeaea';
      this.status_color_button = '#000000';
      this.status_background_slide_btn = '#ffffff';
      this.status_color_slide_btn = '#000000';
      this.CloseModal();
    },
  },
  async created() {
    if (localStorage.getItem('statusTimeSanche') == undefined) {
      localStorage.setItem('statusTimeSanche', this.status_time);
    }
    if (localStorage.getItem('statusArticleColorSanche') == undefined) {
      localStorage.setItem(
        'statusArticleColorSanche',
        this.status_background_pick,
      );
    }
    if (localStorage.getItem('statusMesborTblSanche') == undefined) {
      localStorage.setItem('statusMesborTblSanche', this.status_border_pick);
    }
    if (localStorage.getItem('statusMesboriTblSanche') == undefined) {
      localStorage.setItem('statusMesboriTblSanche', this.status_border_inner);
    }
    if (localStorage.getItem('statusMesboriTblSanche2') == undefined) {
      localStorage.setItem(
        'statusMesboriTblSanche2',
        this.status_border_inner2,
      );
    }
    if (localStorage.getItem('statusTempbgColorSanche') == undefined) {
      localStorage.setItem(
        'statusTempbgColorSanche',
        this.status_background_temp,
      );
    }
    if (localStorage.getItem('statusTempFontSanche') == undefined) {
      localStorage.setItem('statusTempFontSanche', this.status_color_temp);
    }
    if (localStorage.getItem('statusTableColorSanche') == undefined) {
      localStorage.setItem(
        'statusTableColorSanche',
        this.status_background_table,
      );
    }
    if (localStorage.getItem('statusDetailFontSanche') == undefined) {
      localStorage.setItem('statusDetailFontSanche', this.status_color_detail);
    }
    if (localStorage.getItem('statusBtnColorSanche') == undefined) {
      localStorage.setItem(
        'statusBtnColorSanche',
        this.status_background_button,
      );
    }
    if (localStorage.getItem('statusBtnFontColorSanche') == undefined) {
      localStorage.setItem(
        'statusBtnFontColorSanche',
        this.status_color_button,
      );
    }
    if (localStorage.getItem('statusSlideBtnColorSanche') == undefined) {
      localStorage.setItem(
        'statusSlideBtnColorSanche',
        this.status_background_slide_btn,
      );
    }
    if (localStorage.getItem('statusSlideBtniColorSanche') == undefined) {
      localStorage.setItem(
        'statusSlideBtniColorSanche',
        this.status_color_slide_btn,
      );
    }
  },
};
</script>

<style lang="scss" scoped></style>
