<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorSanche};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="date" :style="`color: ${statusTimeSanche};`">
          조회시간: {{ str_time }}
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(items, index2) in filteredLotList"
          :key="index2"
          class="mes_tbl_wrap swiper-slide"
          :style="`border: 2px solid ${statusMesborTblSanche}`"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="7"
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  포장지시 현황 {{ index2 == 1 ? '(차주)' : '' }}
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                ></th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  분류
                </th>

                <th
                  v-for="(n, index3) in 5"
                  :key="index3"
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  {{ weekday(index3 + 1, index2) }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in items.maxDay" :key="index">
              <tr>
                <td
                  rowspan="3"
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche}; border-bottom: 0 !important;`
                      : `background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2} !important;`
                  "
                >
                  {{ index + 1 }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  품명
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.mon.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.mon[index].product_id)
                          .name
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.tue.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.tue[index].product_id)
                          .name
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.wed.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.wed[index].product_id)
                          .name
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.thu.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.thu[index].product_id)
                          .name
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.fri.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.fri[index].product_id)
                          .name
                  }}
                </td>
              </tr>
              <tr>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  규격
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.mon.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.mon[index].product_id)
                          .standard
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.tue.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.tue[index].product_id)
                          .standard
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.wed.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.wed[index].product_id)
                          .standard
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.thu.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.thu[index].product_id)
                          .standard
                  }}
                </td>
                <td
                  :style="
                    `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  {{
                    compareLength(items.fri.length, index + 1)
                      ? ''
                      : findInfoFromId(products, items.fri[index].product_id)
                          .standard
                  }}
                </td>
              </tr>
              <tr>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  수량
                </td>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  <b>{{
                    compareLength(items.mon.length, index + 1)
                      ? ''
                      : $makeComma(items.mon[index].quantity)
                  }}</b>
                  {{
                    compareLength(items.mon.length, index + 1)
                      ? ''
                      : findInfoFromId(
                          units,
                          findInfoFromId(products, items.mon[index].product_id)
                            .stock_unit_id,
                        ).name
                  }}
                </td>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  <b>{{
                    compareLength(items.tue.length, index + 1)
                      ? ''
                      : $makeComma(items.tue[index].quantity)
                  }}</b>
                  {{
                    compareLength(items.tue.length, index + 1)
                      ? ''
                      : findInfoFromId(
                          units,
                          findInfoFromId(products, items.tue[index].product_id)
                            .stock_unit_id,
                        ).name
                  }}
                </td>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  <b>{{
                    compareLength(items.wed.length, index + 1)
                      ? ''
                      : $makeComma(items.wed[index].quantity)
                  }}</b>
                  {{
                    compareLength(items.wed.length, index + 1)
                      ? ''
                      : findInfoFromId(
                          units,
                          findInfoFromId(products, items.wed[index].product_id)
                            .stock_unit_id,
                        ).name
                  }}
                </td>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  <b>{{
                    compareLength(items.thu.length, index + 1)
                      ? ''
                      : $makeComma(items.thu[index].quantity)
                  }}</b>
                  {{
                    compareLength(items.thu.length, index + 1)
                      ? ''
                      : findInfoFromId(
                          units,
                          findInfoFromId(products, items.thu[index].product_id)
                            .stock_unit_id,
                        ).name
                  }}
                </td>
                <td
                  :style="
                    items.maxDay.length - 1 == index
                      ? `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 0;`
                      : `background-color: ${statusTableColorSanche}; border-left: 2px solid ${statusMesboriTblSanche2}; color: ${statusDetailFontSanche}; border-bottom: 2px solid ${statusMesboriTblSanche2};`
                  "
                >
                  <b>
                    {{
                      compareLength(items.fri.length, index + 1)
                        ? ''
                        : $makeComma(items.fri[index].quantity)
                    }}
                  </b>
                  {{
                    compareLength(items.fri.length, index + 1)
                      ? ''
                      : findInfoFromId(
                          units,
                          findInfoFromId(products, items.fri[index].product_id)
                            .stock_unit_id,
                        ).name
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent.stop="autoPlay"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { formatDateNoHours } from '@/utils/filters';

export default {
  mixins: [SWIPER_MIXIN, fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      startDate: null,
      endDate: null,

      statusTimeSanche: localStorage.getItem('statusTimeSanche'),
      statusArticleColorSanche: localStorage.getItem(
        'statusArticleColorSanche',
      ),
      statusMesborTblSanche: localStorage.getItem('statusMesborTblSanche'),
      statusMesboriTblSanche: localStorage.getItem('statusMesboriTblSanche'),
      statusMesboriTblSanche2: localStorage.getItem('statusMesboriTblSanche2'),
      statusTempbgColorSanche: localStorage.getItem('statusTempbgColorSanche'),
      statusTempFontSanche: localStorage.getItem('statusTempFontSanche'),
      statusTableColorSanche: localStorage.getItem('statusTableColorSanche'),
      statusDetailFontSanche: localStorage.getItem('statusDetailFontSanche'),
      statusBtnColorSanche: localStorage.getItem('statusBtnColorSanche'),
      statusBtnFontColorSanche: localStorage.getItem(
        'statusBtnFontColorSanche',
      ),
      statusSlideBtnColorSanche: localStorage.getItem(
        'statusSlideBtnColorSanche',
      ),
      statusSlideBtniColorSanche: localStorage.getItem(
        'statusSlideBtniColorSanche',
      ),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
      lots: 'getLot',
    }),
    filteredLotList() {
      const lotsTemp = this.lodash.clonedeep(this.lots);
      let weekLotList = [];
      if (lotsTemp.length > 0) {
        let lotList = lotsTemp;
        lotList = lotList.sort(
          (a, b) => new Date(a.input_date) - new Date(b.input_date),
        );
        let mondayList = [],
          tuesdayList = [],
          wednesdayList = [],
          thursdayList = [],
          fridayList = [];
        let NextMondayList = [],
          NextTuesdayList = [],
          NextWednesdayList = [],
          NextThursdayList = [],
          NextFridayList = [];
        let cnt = 0;
        let chkLen = 0;
        let NchkLen = 0;
        let maxLen = 0;
        let NmaxLen = 0;
        let compareDate = '';

        if (this.startDate != null) {
          compareDate = this.startDate;
        }
        let maxDay = [];
        let NmaxDay = [];
        lotList = lotList
          .map(x => {
            x.lot_detail = x.lot_detail.map(y => {
              y.bom_id = x.bom_id;
              y.company_id = x.company_id;
              y.create_time = x.create_time;
              y.custom_input_yn = x.custom_input_yn;
              y.deadline_date = x.deadline_date;
              y.detail = x.detail;
              y.input_date = x.input_date;
              y.lot_number = x.lot_number;
              y.lot_type_id = x.lot_type_id;
              y.product_id = y.member_product_id;
              y.sales_id = x.sales_id;
              return y;
            });
            return x.lot_detail;
          })
          .reduce((a, b) => a.concat(b))
          .filter(
            x =>
              x.quantity > 0 &&
              !this.findInfoFromId(this.products, x.product_id).oem_product_yn,
          );

        lotList.forEach(el => {
          if (el.input_date != compareDate) {
            let gap = Math.ceil(
              (new Date(el.input_date).getTime() -
                new Date(compareDate).getTime()) /
                (1000 * 3600 * 24),
            );
            cnt = cnt + gap;
            compareDate = el.input_date;
            chkLen = 0;
            NchkLen = 0;
          }

          if (cnt == 0) {
            mondayList.push(el);
            compareDate = el.input_date;
            chkLen++;
            if (chkLen > maxLen) {
              maxLen = chkLen;
              maxDay = mondayList;
            }
          } else if (cnt == 1) {
            tuesdayList.push(el);
            compareDate = el.input_date;
            chkLen++;
            if (chkLen > maxLen) {
              maxLen = chkLen;
              maxDay = tuesdayList;
            }
          } else if (cnt == 2) {
            wednesdayList.push(el);
            compareDate = el.input_date;
            chkLen++;
            if (chkLen > maxLen) {
              maxLen = chkLen;
              maxDay = wednesdayList;
            }
          } else if (cnt == 3) {
            thursdayList.push(el);
            compareDate = el.input_date;
            chkLen++;
            if (chkLen > maxLen) {
              maxLen = chkLen;
              maxDay = thursdayList;
            }
          } else if (cnt == 4) {
            fridayList.push(el);
            compareDate = el.input_date;
            chkLen++;
            if (chkLen > maxLen) {
              maxLen = chkLen;
              maxDay = fridayList;
            }
          } else if (cnt == 7) {
            NextMondayList.push(el);
            NchkLen++;
            if (NchkLen > NmaxLen) {
              NmaxLen = NchkLen;
              NmaxDay = NextMondayList;
            }
          } else if (cnt == 8) {
            NextTuesdayList.push(el);
            NchkLen++;
            if (NchkLen > NmaxLen) {
              NmaxLen = NchkLen;
              NmaxDay = NextTuesdayList;
            }
          } else if (cnt == 9) {
            NextWednesdayList.push(el);
            NchkLen++;
            if (NchkLen > NmaxLen) {
              NmaxLen = NchkLen;
              NmaxDay = NextWednesdayList;
            }
          } else if (cnt == 10) {
            NextThursdayList.push(el);
            NchkLen++;
            if (NchkLen > NmaxLen) {
              NmaxLen = NchkLen;
              NmaxDay = NextThursdayList;
            }
          } else if (cnt == 11) {
            NextFridayList.push(el);
            NchkLen++;
            if (NchkLen > NmaxLen) {
              NmaxLen = NchkLen;
              NmaxDay = NextFridayList;
            }
          }
        });

        weekLotList.push({
          mon: mondayList,
          tue: tuesdayList,
          wed: wednesdayList,
          thu: thursdayList,
          fri: fridayList,
          maxDay: maxDay,
        });

        weekLotList.push({
          mon: NextMondayList,
          tue: NextTuesdayList,
          wed: NextWednesdayList,
          thu: NextThursdayList,
          fri: NextFridayList,
          maxDay: NmaxDay,
        });

        return weekLotList;
      } else {
        return weekLotList;
      }
    },
  },
  methods: {
    compareLength(leng, index) {
      return leng < index;
    },
    async refreshData() {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.FETCH_LOT_DAY();
    },
    weekday(index, index2) {
      let currentDay = new Date();
      if (index2 == 1)
        currentDay = new Date(currentDay.setDate(currentDay.getDate() + 7));
      let theYear = currentDay.getFullYear();
      let theMonth = currentDay.getMonth();
      let theDate = currentDay.getDate();
      let theDayOfWeek = currentDay.getDay();
      const week = ['일', '월', '화', '수', '목', '금', '토'];
      let thisWeek = [];

      for (let i = 0; i < 7; i++) {
        let resultDay = new Date(
          theYear,
          theMonth,
          theDate + (i - theDayOfWeek),
        );
        let yyyy = resultDay.getFullYear();
        let mm = Number(resultDay.getMonth()) + 1;
        let dd = resultDay.getDate();

        mm = String(mm).length == 1 ? '0' + mm : mm;
        dd = String(dd).length == 1 ? '0' + dd : dd;

        thisWeek[i] = yyyy + '-' + mm + '-' + dd;
      }

      let dayOfWeek = week[new Date(thisWeek[index]).getDay()];

      return `${dayOfWeek} ${thisWeek[index].substr(8, 2)}`;
    },
    FETCH_LOT_DAY() {
      this.$store
        .dispatch('FETCH_LOT_DAY', {
          start: this.startDate,
          end: this.endDate,
        })
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '작업지시 정보를 불러오는 중 오류가 발생했습니다.',
          );
        });
    },
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let date = today.getDate();
    let dayOfWeek = today.getDay();
    this.startDate = formatDateNoHours(
      new Date(year, month, date + (1 - dayOfWeek)),
    );
    this.endDate = formatDateNoHours(
      new Date(year, month, date + (13 - dayOfWeek)),
    );

    await this.FETCH_LOT_DAY();

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        this.FETCH_LOT_DAY();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
