<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorSanche};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="date" :style="`color: ${statusTimeSanche};`">
          조회시간: {{ str_time }}
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          :style="`border: 2px solid ${statusMesborTblSanche}`"
          v-for="(items, index) in filteredRequest"
          :key="index"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="5"
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  출고지시 현황
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  매출처
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  제품명
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  규격
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  수량(단위)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(request, index2) in items" :key="request.id">
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 0; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{
                    findInfoFromId(
                      companys,
                      findInfoFromId(sales, request.sales_id).company_id,
                    ).name
                  }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ findInfoFromId(products, request.product_id).name }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ request.standard }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{
                    `${$makeComma(request.quantity)}(${
                      findInfoFromId(
                        units,
                        findInfoFromId(products, request.product_id)
                          .stock_unit_id,
                      ).name
                    })`
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </swiper-slide>
        <!-- </div> -->
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent.stop="autoPlay"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [SWIPER_MIXIN, fetchMixin],
  data() {
    return {
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTimeSanche: localStorage.getItem('statusTimeSanche'),
      statusArticleColorSanche: localStorage.getItem(
        'statusArticleColorSanche',
      ),
      statusMesborTblSanche: localStorage.getItem('statusMesborTblSanche'),
      statusMesboriTblSanche: localStorage.getItem('statusMesboriTblSanche'),
      statusMesboriTblSanche2: localStorage.getItem('statusMesboriTblSanche2'),
      statusTempbgColorSanche: localStorage.getItem('statusTempbgColorSanche'),
      statusTempFontSanche: localStorage.getItem('statusTempFontSanche'),
      statusDivColorSanche: localStorage.getItem('statusDivColorSanche'),
      statusDivFontSanche: localStorage.getItem('statusDivFontSanche'),
      statusTableColorSanche: localStorage.getItem('statusTableColorSanche'),
      statusDetailFontSanche: localStorage.getItem('statusDetailFontSanche'),
      statusDetailTempFontSanche: localStorage.getItem(
        'statusDetailTempFontSanche',
      ),
      statusBtnColorSanche: localStorage.getItem('statusBtnColorSanche'),
      statusBtnFontColorSanche: localStorage.getItem(
        'statusBtnFontColorSanche',
      ),
      statusSlideBtnColorSanche: localStorage.getItem(
        'statusSlideBtnColorSanche',
      ),
      statusSlideBtniColorSanche: localStorage.getItem(
        'statusSlideBtniColorSanche',
      ),
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        try {
          let filterClone = salesClone
            .map(x => x.product_list)
            .reduce((a, b) => a.concat(b))
            .sort((a, b) => b.id - a.id)
            .filter(x => x.request_out);
          let arr = [];
          if (this.fullScreen) {
            filterClone.forEach((element, index) => {
              if (index % 9 == 0) {
                arr.push(filterClone.slice(index, index + 9));
              }
            });
          } else {
            filterClone.forEach((element, index) => {
              if (index % 5 == 0) {
                arr.push(filterClone.slice(index, index + 5));
              }
            });
          }
          return arr;
        } catch (error) {
          return [];
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
