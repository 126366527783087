<template>
  <div
    id="contents"
    class="status_board_blue sanche_status_default"
    :class="{
      work_order_status: tabIndex == 0,
      work_order_package_status: tabIndex == 1,
      request_status: tabIndex == 2,
      finished_status: tabIndex == 3,
      daily_output: tabIndex == 4,
      color_setting: tabIndex == 5,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="[
            { title: '작업지시 현황', name: 'work_order_status' },
            { title: '포장지시 현황', name: 'work_order_package_status' },
            { title: '출고지시 현황', name: 'request_status' },
            { title: '완제품 재고현황', name: 'finished_status' },
            { title: '일일 생산량', name: 'daily_output' },
            { title: '현황판 색 설정', name: 'status_board_color_picker' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">작업지시 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">포장지시 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">출고지시 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">완제품 재고현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">일일 생산량</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">현황판 색 설정</a>
          </li>
        </ul>
      </div>
      <work-order-status-form v-if="tabIndex == 0"></work-order-status-form>
      <work-order-package-status-form
        v-if="tabIndex == 1"
      ></work-order-package-status-form>
      <request-status-form v-if="tabIndex == 2"></request-status-form>
      <finished-status-form v-if="tabIndex == 3"></finished-status-form>
      <daily-output-form v-if="tabIndex == 4"></daily-output-form>
      <status-color-picker-form v-if="tabIndex == 5"></status-color-picker-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import WorkOrderStatusForm from '@/views/forms/Custom/Monitor/5/WorkOrderStatusForm';
import WorkOrderPackageStatusForm from '@/views/forms/Custom/Monitor/5/WorkOrderPackageStatusForm';
import RequestStatusForm from '@/views/forms/Custom/Monitor/5/RequestStatusForm';
import FinishedStatusForm from '@/views/forms/Custom/Monitor/5/FinishedStatusForm';
import DailyOutputForm from '@/views/forms/Custom/Monitor/5/DailyOutputForm';
import StatusColorPickerForm from '@/views/forms/Custom/Monitor/5/StatusColorPickerForm';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    WorkOrderStatusForm,
    WorkOrderPackageStatusForm,
    RequestStatusForm,
    FinishedStatusForm,
    DailyOutputForm,
    StatusColorPickerForm,
  },
  data() {
    return {
      swiper: null,
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'sanche_monitoring';
</style>
