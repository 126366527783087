<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColorSanche};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="date" :style="`color: ${statusTimeSanche};`">
          조회시간: {{ str_time }}
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColorSanche}; color: ${statusBtnFontColorSanche};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filteredProductStatus"
          :key="index"
          :style="`border: 2px solid ${statusMesborTblSanche}`"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="9"
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  일일 생산현황
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  No.
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  제품명
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  규격
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTblSanche}; border-bottom: 2px solid ${statusMesboriTblSanche}; background-color: ${statusTempbgColorSanche}; color: ${statusTempFontSanche};`
                  "
                >
                  수량(단위)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(productStatus, index2) in items"
                :key="productStatus.id"
              >
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 0; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ index2 + 1 }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ productStatus.product_name }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{ productStatus.product_standard }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 0; background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                      : `border-left: 2px solid ${statusMesboriTblSanche2}; border-bottom: 2px solid ${statusMesboriTblSanche2};
                    background-color: ${statusTableColorSanche}; color: ${statusDetailFontSanche};`
                  "
                >
                  {{
                    `${$makeComma(productStatus.quantity)}(${
                      findInfoFromId(
                        unitCodes,
                        findInfoFromId(product, productStatus.product_id)
                          .stock_unit_id,
                      ).name
                    })`
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </swiper-slide>
        <!-- </div> -->
      </swiper>
      <div class="slide_btn_wrap">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent.stop="autoPlay"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColorSanche};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColorSanche};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [SWIPER_MIXIN, fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTimeSanche: localStorage.getItem('statusTimeSanche'),
      statusArticleColorSanche: localStorage.getItem(
        'statusArticleColorSanche',
      ),
      statusMesborTblSanche: localStorage.getItem('statusMesborTblSanche'),
      statusMesboriTblSanche: localStorage.getItem('statusMesboriTblSanche'),
      statusMesboriTblSanche2: localStorage.getItem('statusMesboriTblSanche2'),
      statusTempbgColorSanche: localStorage.getItem('statusTempbgColorSanche'),
      statusTempFontSanche: localStorage.getItem('statusTempFontSanche'),
      statusDivColorSanche: localStorage.getItem('statusDivColorSanche'),
      statusDivFontSanche: localStorage.getItem('statusDivFontSanche'),
      statusTableColorSanche: localStorage.getItem('statusTableColorSanche'),
      statusDetailFontSanche: localStorage.getItem('statusDetailFontSanche'),
      statusDetailTempFontSanche: localStorage.getItem(
        'statusDetailTempFontSanche',
      ),
      statusBtnColorSanche: localStorage.getItem('statusBtnColorSanche'),
      statusBtnFontColorSanche: localStorage.getItem(
        'statusBtnFontColorSanche',
      ),
      statusSlideBtnColorSanche: localStorage.getItem(
        'statusSlideBtnColorSanche',
      ),
      statusSlideBtniColorSanche: localStorage.getItem(
        'statusSlideBtniColorSanche',
      ),
    };
  },
  computed: {
    ...mapGetters({
      productStatusList: 'getProductStatusListFromDailyOutput',
      product: 'getProduct',
      unitCodes: 'getUnitCodes',
      productInOutType: 'getProductInOutType',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredProductStatus() {
      if (this.productStatusList !== undefined) {
        const date = new Date();
        let filterToday = this.productStatusList.filter(
          x =>
            x.input_date == yyyymmdd(date) &&
            !this.findInfoFromId(this.product, x.product_id).oem_product_yn,
        );
        let filterInOutType = filterToday.filter(x =>
          this.findInfoFromId(
            this.productInOutType,
            x.product_in_out_type_id == 1,
          ),
        );
        let arr = [];
        if (this.fullScreen) {
          filterInOutType.forEach((element, index) => {
            if (index % 9 == 0) {
              arr.push(filterInOutType.slice(index, index + 9));
            }
          });
        } else {
          filterInOutType.forEach((element, index) => {
            if (index % 5 == 0) {
              arr.push(filterInOutType.slice(index, index + 5));
            }
          });
        }
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async refreshData() {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    this.FETCH('FETCH_UNIT', '단위');
    this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '자재 입출고 유형');
    if (this.productStatusList.length < 1) {
      this.FETCH('FETCH_PRODUCT_STATUS_DAY_DAILY', '제품 재고');
    }
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 3) {
        await this.FETCH_NO_SPINNER(
          'FETCH_PRODUCT_STATUS_DAY_DAILY',
          '제품 재고',
        );
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
