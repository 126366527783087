var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status_board_blue sanche_status_default",class:{
    work_order_status: _vm.tabIndex == 0,
    work_order_package_status: _vm.tabIndex == 1,
    request_status: _vm.tabIndex == 2,
    finished_status: _vm.tabIndex == 3,
    daily_output: _vm.tabIndex == 4,
    color_setting: _vm.tabIndex == 5,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToStatusMonitorPage',"options":[
          { title: '작업지시 현황', name: 'work_order_status' },
          { title: '포장지시 현황', name: 'work_order_package_status' },
          { title: '출고지시 현황', name: 'request_status' },
          { title: '완제품 재고현황', name: 'finished_status' },
          { title: '일일 생산량', name: 'daily_output' },
          { title: '현황판 색 설정', name: 'status_board_color_picker' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("작업지시 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("포장지시 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("출고지시 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 3 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("완제품 재고현황")])]),_c('li',{class:{ active: _vm.tabIndex == 4 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(4)}}},[_vm._v("일일 생산량")])]),_c('li',{class:{ active: _vm.tabIndex == 5 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(5)}}},[_vm._v("현황판 색 설정")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('work-order-status-form'):_vm._e(),(_vm.tabIndex == 1)?_c('work-order-package-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('request-status-form'):_vm._e(),(_vm.tabIndex == 3)?_c('finished-status-form'):_vm._e(),(_vm.tabIndex == 4)?_c('daily-output-form'):_vm._e(),(_vm.tabIndex == 5)?_c('status-color-picker-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }